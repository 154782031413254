import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.p`
  margin-top: 1.5rem;
`;


export const LogoContainer = styled(Link)`
  display: flex;
  padding-top: 1rem;
`;

export const Container = styled.div`
  position: relative;
  max-width: 700px;
  grid-direction: row;
  justify-content: center;
  align-text:center;
`;

export const TextWrapper = styled.div`
  border-radius: 3rem;
  max-width: 400px;
`;
