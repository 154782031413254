const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },

];

export default routes;
