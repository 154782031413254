import { withTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import { List, Divider, Row, Col, Layout,Typography } from 'antd'
import * as S from "./styles";
import Container from "../../common/Container";
import Footer from '../Footer'
import SvgIcon from "../../common/SvgIcon";
// import { Footer } from "../Footer/index.js";
const data = [
    {
        title: "Software Development",
        list: [
            "Custom Software development",
            "Application developlment & maintenance",
            "UX & UI Design"
        ]
    },
    {
        title: "IT Services",
        list: [
            "Software Testing and Automation",
            "Selenium Automation"
        ]
    },
    {
        title: "IT Consulting",
        list: [
            "Technology Consulting",
            "Permanent Placement Services",
            "Resource Supply"
        ]
    }
];
const Services = () => {
    return (
        <div>

            <Container>
            <S.LogoContainer to="/" aria-label="homepage">
                <SvgIcon src="logo.svg" />
            </S.LogoContainer>
                <Fade left>
                    {/* <h6>Our Services</h6> */}
                    <Divider orientation="left"><h3>Our Services</h3></Divider>
                    <Layout.Content>
                        <Row gutter="16">
                            {data.map(data =>

                                <Col key={data.title}
                                //  className="gutter-row" 
                                 span={10
                                }><List
                                    header={<div><strong>{data.title}</strong></div>}
                                    bordered
                                    dataSource={data.list}
                                    renderItem={item => (
                                        <List.Item>
                                            <Typography>{item}</Typography>
                                        </List.Item>
                                    )}
                                /></Col>
                            )}
                        </Row>
                        </Layout.Content>
                        </Fade>
            </Container>
            <Footer />
        </div>
    );
};

export default withTranslation()(Services);
